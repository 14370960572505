import React, { useState, useContext } from "react";
import UpdatesModal from '../../components/UpdatesModal';
import makeStyles from '@mui/styles/makeStyles';
//import 'rsuite/dist/styles/rsuite-default.css'; // or 'rsuite/dist/styles/rsuite-default.css'
import ResumeChart from "./ResumeChart";
import { Box, Tab, Tabs } from "@mui/material";
import Report from "../Report";
import TabPanel from "../../components/TabPanel";
import { useHistory } from "react-router-dom";
import { BiHelpCircle } from "react-icons/bi";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { i18n } from "../../translate/i18n";
import Metrics from "../../components/Metrics";
import ForbiddenPage from "../../components/ForbiddenPage";

const useStyles = makeStyles(theme => ({
    root: {
        background: theme.palette.fancyBackground,
        padding: 15,
        '& h2': {
            color: theme.palette.dark.main
        }
    },
    tabLabel: {
        color: theme.palette.text.primary,
    },
    mainTabs: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between'
    },
    linkHelp: {
        color: theme.palette.text.primary,
        cursor: 'pointer',
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        gap: 5,
        fontSize: 16,
        fontWeight: 'bold',
    }
}));

const Dashboard = () => {
    const classes = useStyles();
    const history = useHistory();
    const [tab, setTab] = useState(0);
    const { user, permissions } = useContext(AuthContext);
    const hasPermissionReports = permissions.some((permission) => permission.key === 'dashboard:show:reports') || user.roleId === 1;
    const hasPermissionMetrics = permissions.some((permission) => permission.key === 'dashboard:show:metrics')  || user.roleId === 1;

    const handleTabChange = (event, newValue) => {
        setTab(newValue);
    }

    return (
        <div className={classes.root}>
            <UpdatesModal />
            <Can
                role={user.roleId}
                perform="dashboard:show:page"
                yes={() => (
                    <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className={classes.mainTabs}>
                            <Tabs value={tab} onChange={handleTabChange} aria-label="basic tabs example" className="tabBlock">
                                <Tab className={classes.tabLabel} label={i18n.t("dashboardHome.general")} />
                                <Tab className={classes.tabLabel} label={i18n.t("dashboardHome.tickets")} style={{display: hasPermissionReports ? 'block': 'none'}} />
                                <Tab className={classes.tabLabel} label={i18n.t("dashboardHome.metrics")} style={{display: hasPermissionMetrics ? 'block': 'none'}} />
                            </Tabs>
                            <div onClick={() => history.push('/docs')}
                                className={`${classes.linkHelp} link-help`}><BiHelpCircle /> {i18n.t("dashboardHome.help")}</div>
                        </Box>
                        <TabPanel value={tab} name={0}>
                            <ResumeChart />
                        </TabPanel>
                        <TabPanel value={tab} name={1}>
                            <Report />
                        </TabPanel>
                        <TabPanel value={tab} name={2}>
                            <Can
                                role={user.roleId}
                                perform="dashboard:show:metrics"
                                yes={() => (
                                    <Metrics />)}
                                no={() => <ForbiddenPage />}
                            />
                        </TabPanel>

                    </>)}
                no={() => <></>}
            />
        </div>
    );
};

export default Dashboard;
