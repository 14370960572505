import React, { useState, useEffect, useContext } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import makeStyles from '@mui/styles/makeStyles';
import { Paper, Box, Button, Grid, Tooltip, IconButton } from "@mui/material";
import SelectTags from "../../components/SelectTags";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectStatus from "../../components/SelectStatus";
import SelectContacts from "../../components/SelectContacts";
import DateRangePicker from "../../components/DateRangePicker";
import * as moment from 'moment';
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { i18n } from "../../translate/i18n";
import { MdPrint } from "react-icons/md";
import { PiBrain } from "react-icons/pi";
import { FaChartBar, FaEye, FaInfoCircle, FaTable } from "react-icons/fa";
import PromptReportsAIModal from "../PromptReportsAIModal";
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { ResponsiveFunnel } from '@nivo/funnel';
import BarChart2 from "./barChart";
import { FormControl } from "@mui/material";
import ColorChartSelect from "../../helpers/colorChart";
import QtyChart from "./qtyChart";
import { AuthContext } from "../../context/Auth/AuthContext";
import { display } from "@mui/system";

const useStyles = makeStyles(theme => ({
    box: {
        backgroundColor: theme.palette.background.paper,
        padding: 20,
        position: "relative",
        color: theme.palette.dark.main,
        alignItems: 'center',
        display: 'flex',

    },
    wrapperSelect: {
        padding: 10
    },
    blockDash: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #ddd',
        padding: 15,
        borderRadius: 20,
        margin: 10
    },
    mainPaperTable: {
        marginBottom: 30
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        gap: 10
    }
}));

const Metrics = () => {
    const classes = useStyles();
    const [key, setkey] = useState(false)
    const size = useWindowDimensions();
    const { user, permissions } = useContext(AuthContext);
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);
    const [userIds, setUserIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [internalLoading, setInternalLoading] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [loadingResponseTime, setLoadingResponseTime] = useState(false);
    const [loadingTicketTime, setLoadingTicketTime] = useState(false);
    const [totalTime, setTimeTotal] = useState(0);
    const [totalContacts, setTotalContacts] = useState(0);
    const [totalMsg, setTotalMsg] = useState(0);
    const [openTickets, setOpenTickets] = useState(0);
    const [doneTickets, setDoneTickets] = useState(0);
    const [dataUsersRanking, setDataUsersRanking] = useState([]);
    const [dataClientsRanking, setDataClientsRanking] = useState([]);

    const [reportsAIModalOpen, setReportAIModalOpen] = useState(false);
    const [titleChart, setTitleChart] = useState('');
    const [dataAi, setDataAI] = useState(null);
    const [funnelData, setFunnelData] = useState(null);
    const [selectedTheme, setSelectedTheme] = useState('nivo');
    const hasMetricsPermissionAll = permissions.some((permission) => permission.key === 'dashboard:show:metrics:all');
    const hasMetricsPermissionAllQueue = permissions.some((permission) => permission.key === 'dashboard:show:metrics:all:queue');

    const dataFunnil = [
        { id: 'contact-count', value: totalContacts, label: "Qntd. de Contatos Novos" },
        { id: 'open-count', value: openTickets, label: "Tickets Abertos" },
        { id: 'done-count', value: doneTickets, label: "Tickets Finalizados" },
    ];

    const fetchMetrics = async (id, queues) => {
        //console.log(period, 'was period')
        setLoading(true)
        try {
            setLoading(true);
            const response = await api.get("/report/metrics/", {
                params: {
                    dateStart: moment(period[0]).format('YYYY-MM-DD'),
                    dateEnd: moment(period[1]).format('YYYY-MM-DD'),
                    contactIds: JSON.stringify(contactIds),
                    userIds: id ? JSON.stringify([id]) : JSON.stringify(userIds),
                    queueIds: queues ? JSON.stringify(queues) : JSON.stringify(queueIds),
                    statuses: JSON.stringify(statuses),
                    tagIds: JSON.stringify(tagIds),
                    keys: JSON.stringify(selectedMetrics)
                },
            });

            //console.log(response, 'teste');
            if (response.data) {

                response.data.data.forEach((item) => {
                    //console.log(item, 'inside foreach');
                    if (item.totalTime) {
                        setTimeTotal(item.totalTime[0].totalMinutes)
                    }

                    if (item.totalContacts) {
                        setTotalContacts(item.totalContacts)
                    }

                    if (item.userMessageCount) {
                        setTotalMsg(item.userMessageCount)
                    }

                    if (item.totalOpenTickets) {
                        setOpenTickets(item.totalOpenTickets)
                    }

                    if (item.totalDoneTickets) {
                        setDoneTickets(item.totalDoneTickets)
                    }

                    if (item.userRanking) {
                        setDataUsersRanking(item.userRanking)
                    }

                    if (item.clientsRanking) {
                        setDataClientsRanking(item.clientsRanking)
                    }

                    if (item.usersMessages) {
                        setDataUsersMessages(item.usersMessages);
                    }

                    if (item.answeredTickets) {
                        setDataTicketsAnswered(item.answeredTickets)
                    }
                });
            }

            setLoading(false)
        } catch (err) {
            toastError(err);
        }
    };


    useEffect(() => {
        async function getReportUser() {
            if ((user && user.roleId === 1) || hasMetricsPermissionAll) {
                setUserIds([]);
                fetchMetrics();
                setLoading(false);
            } else {

                if (user && user.queues && user.queues.length >= 1 && (hasMetricsPermissionAllQueue && !hasMetricsPermissionAll)) {
                    //console.log(user.queues, 'find user.queues');
                    let newQueues = []
                    user.queues.forEach((values) => {
                        if (!queueIds.includes(values.id)) {
                            setQueueIds(prevQueueIds => [...prevQueueIds, values.id]);
                            newQueues.push(values.id);
                        }
                    });
                    //console.log(newQueues)
                    fetchMetrics(null, newQueues);
                }
                else {
                    setUserIds([user.id]);
                    fetchMetrics(user.id);
                }


                setUserIds([user.id]);

                setLoading(false);
            }
        }
        getReportUser();
    }, [user]);




    const [selectedMetrics, setSelectedMetrics] = useState([
        'totalTime',
        'totalOpenTickets',
        'totalDoneTickets',
        'answeredTickets',
        'totalContacts',
        //'queueMessages',
        'clientsRanking',
        'usersRanking',
        'usersMessageCount',
        'usersMessages'
    ]);
    //const { user } = useContext(AuthContext);


    const handleChangeSelectedTheme = (data) => {
        //console.log(data.target.value)
        setSelectedTheme(data.target.value)
        window.localStorage.setItem('selectedThemeChart', data.target.value);
    }

    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    const handleChangeStatuses = (values) => {
        // console.log(values)
        setStatuses(values.map((item) => item.id));
    }



    /**Data  Mensagens Trocadas por Atendente*/
    const [dataUsersMessages, setDataUsersMessages] = useState([]);

    /* Mensagens trocadas */
    const [dataTicketsAnswered, setDataTicketsAnswered] = useState([]);

    const optionsTicketsAnswered = {
        chart: {
            title: "Tickets Atendidos",
            subtitle: "números reais",
        },
    };



    //Mui datatables
    const rowsUserRanking = dataUsersRanking || [];
    const columnsUserRanking = [
        { field: 'name', headerName: 'Atendente', flex: 1 },
        { field: 'qtdMsg', headerName: 'Nº Msgs', flex: 1 },
        {
            field: 'timeTicket',
            headerName: 'Tempo de atendimento',
            flex: 1,
            valueFormatter: (params) => formatTime(Number(params.value)),
        },
    ];
    const getRowUserRankingId = (row) => rowsUserRanking.indexOf(row);

    const [sortUsersRankingModel, setSortUserRankingModel] = useState([
        {
            field: 'qtdMsg',
            sort: 'desc',
        },
    ]);


    const rowsClientsRanking = dataClientsRanking || [];
    const columnsClientsRanking = [
        { field: 'name', headerName: 'Nome do contato', flex: 1 },
        // Add more columns as needed
        { field: 'userName', headerName: 'Atendente', flex: 1 },
        { field: 'qtdMsg', headerName: 'Nº Msgs', flex: 1 },
        {
            field: 'timeTickets',
            headerName: 'Tempo de atendimento',
            flex: 1,
            valueFormatter: (params) => formatTime(Number(params.value)),
        },
    ];
    const getRowClientsRankingId = (row) => rowsClientsRanking.indexOf(row);

    const [sortClientsRankingModel, setSortClientsRankingModel] = useState([
        {
            field: 'qtdMsg',
            sort: 'desc',
        },
    ]);






    function formatTime(seconds) {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = Math.floor(seconds % 60);

        const formattedTime = `${padZero(hours)}h:${padZero(minutes)}m:${padZero(remainingSeconds)}s`;
        return formattedTime;
    }

    function padZero(num) {
        return num < 10 ? `0${num}` : num;
    }



    async function handleAi(type) {
        if (type === 'rankingMsg') {
            setDataAI(dataUsersMessages)
            setTitleChart('Ranking de Mensagens Trocadas por Atendente')
        } else if (type === 'rankingTickets') {
            setDataAI(dataTicketsAnswered)
            setTitleChart('Ranking de Tickets Atendidos')
        } else if (type === 'rankingUsers') {
            setDataAI(dataUsersRanking)
            setTitleChart('Ranking Atendentes que mais trocaram mensagem')
        } else if (type === 'rankingContacts') {
            setDataAI(dataClientsRanking)
            setTitleChart('Ranking de Clientes que mais trocaram mensagens');
        }
        setReportAIModalOpen(true)
    }


    const handlePrint = () => {
        setkey(prevKey => !prevKey)
        window.print();
    };

    const [chartMsgsOption, setChartMsgOption] = useState('chart');
    const [chartClientsOption, setChartClientsOption] = useState('chart');

    const handleModeChart = (type, option) => {
        if (type === 'msgsChart') {
            setChartMsgOption(option);
        }

        if (type === 'msgsClients') {
            setChartClientsOption(option);
        }
    }


    useEffect(() => {
        setkey(prevKey => !prevKey)
    }, [size.width])


    return (
        <Box className={classes.box}>
            <PromptReportsAIModal
                open={reportsAIModalOpen}
                onClose={() => setReportAIModalOpen(false)}
                title={titleChart}
                dataAI={dataAi}
            />
            {loading ? (
                <Grid container spacing={0}>
                    <Grid container style={{ padding: 20, alignItems: 'center', minHeight: '60vh', justifyContent: 'center' }}>
                        <div className="flex flex-col items-center justify-center mx-auto sm:w-6/12" style={{ textAlign: 'center' }}>
                            <img src="/loading/loadinginfinite.gif" />
                            <h3>Iniciando análise de métricas</h3>
                            <p>Dependendo da quantidade
                                de mensagens pode levar um tempo</p>
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={0} id="printable">

                    <Grid container style={{ padding: 20, alignItems: 'center' }}>
                        <Grid item xs={12} md={3}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                                <div className={classes.shineEffect}>
                                    <img className={`logo ${classes.logo}`} src="/logo-black.png" alt="Logo" style={{ height: 50, marginBottom: 20 }} />
                                </div>
                                <div className={classes.flex} style={{ flexDirection: 'column' }}>
                                    <Button variant="outlined" onClick={() => handlePrint()} style={{ gap: 10 }}><MdPrint /> Imprimir</Button>
                                    {/** <Button variant="outlined" style={{ gap: 10 }}><FaFilePdf /> Salvar PDF</Button> **/}

                                    <FormControl size="small" variant="outlined" fullWidth>
                                        <ColorChartSelect selectedTheme={selectedTheme} handleSelectChange={handleChangeSelectedTheme} />
                                    </FormControl>
                                </div>
                            </div>

                        </Grid>
                        <Grid item xs={12} md={9} className="noprint">
                            <Grid container>
                                {(hasMetricsPermissionAll || user.roleId === 1) ? (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectUsers
                                                    multiple={true}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                    onChange={handleChangeUserIds} defaultV={userIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectStatus outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeStatuses} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectQueues multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeQueueIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectTags outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeTagIds} />
                                            </div>
                                        </Grid></>) : (
                                    <> <Grid item xs={12} md={4}>
                                        <div className={classes.wrapperSelect}>
                                            {user && user.queues && user.queues.length >= 1 && hasMetricsPermissionAllQueue ? (
                                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column', gap: 5 }}>
                                                    <h4>Setores</h4>
                                                    <div>
                                                        {user.queues.map((values) => (
                                                            <h6 key={values.id} style={{ textTransform: 'capitalize', display: 'flex', alignItems: 'center', marginBottom: 0, gap: 5 }}>
                                                                {/*<div style={{ width: 15, height: 15, background: values.color, borderRadius: 15 }}></div> */} {values.name}
                                                            </h6>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <h4 style={{ textAlign: 'center', textTransform: 'capitalize', marginBottom: 0 }}>Usuário</h4>
                                                    <h3 style={{ textAlign: 'center', textTransform: 'capitalize' }}>{user.name}</h3>
                                                </>
                                            )}
                                        </div>
                                    </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectStatus
                                                    outlined
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                    onChange={handleChangeStatuses} />
                                            </div>
                                        </Grid>
                                    </>
                                )}

                                <Grid item xs={12} md={4}>
                                    <div className={classes.wrapperSelect}>
                                        <DateRangePicker
                                            onChange={(p) => setPeriod(p)}
                                            metrics={true}
                                            value={period}
                                            disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                        />
                                    </div>
                                </Grid>

                                <Grid item xs={12} md={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end' }}>
                                    <Button variant="contained" onClick={() => fetchMetrics()}>Atualizar</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container>
                        <Grid item xs={6} lg={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={classes.blockDash}>
                                <h4>Tempo Atendimento
                                    <Tooltip title={`Este gráfico exibe o número de mensagens respondidas
                                por cada cliente/contato dentro do período de tempo especificado. Ele mostra
                                quantas mensagens cada cliente/contato respondeu.`}>
                                        <IconButton>
                                            <FaInfoCircle size={15} />
                                        </IconButton>
                                    </Tooltip></h4>
                                <h2>{totalTime && Number(totalTime / 60).toFixed(0)}</h2>
                                <span>minutos</span>
                            </div>
                        </Grid>

                        <Grid item xs={6} lg={3} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <div className={classes.blockDash}>
                                <h4>Total de Mensagens
                                    <Tooltip title={`Este gráfico exibe o número de mensagens respondidas
                                por cada cliente/contato dentro do período de tempo especificado. Ele mostra
                                quantas mensagens cada cliente/contato respondeu.`}>
                                        <IconButton>
                                            <FaInfoCircle size={15} />
                                        </IconButton>
                                    </Tooltip></h4>
                                <h2>{totalMsg}</h2>
                                <span>mensagens</span>
                            </div>
                        </Grid>

                        <Grid item xs={12} lg={6} className="">
                            <div style={{ width: '100%', height: '310px', margin: '0 auto' }}>
                                {dataFunnil ?
                                    <ResponsiveFunnel
                                        data={dataFunnil}
                                        margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                                        //valueFormat=">-.4s"
                                        colors={{ scheme: selectedTheme }}
                                        borderWidth={20}
                                        labelColor={{
                                            from: 'color',
                                            modifiers: [
                                                [
                                                    'darker',
                                                    3
                                                ]
                                            ]
                                        }}
                                        beforeSeparatorLength={100}
                                        beforeSeparatorOffset={20}
                                        afterSeparatorLength={100}
                                        afterSeparatorOffset={20}
                                        currentPartSizeExtension={10}
                                        currentBorderWidth={40}
                                        motionConfig="wobbly"
                                    /> : <></>}
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={12} lg={12} style={{ paddingTop: 20 }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h5>Ranking de Mensagens Trocadas por Atendente</h5>
                                <Tooltip title={`Este gráfico exibe o número de mensagens respondidas
                                por cada usuário dentro do período de tempo especificado. Ele mostra
                                quantas mensagens cada usuário respondeu.`}>
                                    <IconButton>
                                        <FaInfoCircle size={15} />
                                    </IconButton>
                                </Tooltip>
                                <Button variant="outlined" style={{ gap: 10, marginLeft: 10 }} onClick={() => handleAi('rankingMsg')}>
                                    <PiBrain /> Entender Gráfico
                                </Button>
                            </div>


                            <div style={{ display: 'flex' }}>
                                <IconButton onClick={() => handleModeChart('msgsChart', 'chart')}>
                                    <FaChartBar />
                                </IconButton>
                                <IconButton onClick={() => handleModeChart('msgsChart', 'table')}>
                                    <FaTable />
                                </IconButton>
                            </div>
                        </div>

                        <div className="chartDash">

                            {chartMsgsOption === 'chart' ? (
                                dataUsersMessages && dataUsersMessages.length >= 1 ?
                                    <div style={{ height: 500, width: '100%', display: 'flex' }}>
                                        <BarChart2
                                            data={dataUsersMessages}
                                            title={'Mensagens Trocadas por Atendente'}
                                            type={'userMessages'}
                                            theme={selectedTheme}
                                        />
                                    </div> : dataUsersMessages && dataUsersMessages.length === 0 ?
                                        <div className="boxChart"
                                            style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            Nada encontrado
                                        </div> :
                                        <div className="boxChart"
                                            style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            Carregando dados...
                                        </div>

                            ) : (
                                <Paper
                                    className={classes.mainPaperTable}
                                    variant="outlined"
                                //  onScroll={handleScroll}
                                >
                                    <div style={{ height: 400, width: '100%' }}>
                                        <DataGrid
                                            rows={rowsUserRanking}
                                            columns={columnsUserRanking}
                                            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                            sortModel={sortUsersRankingModel}
                                            onSortModelChange={(newSortModel) => setSortUserRankingModel(newSortModel)}
                                            pageSize={5}
                                            checkboxSelection
                                            getRowId={getRowUserRankingId}
                                            components={{
                                                NoRowsOverlay: () => (
                                                    <div style={{ textAlign: 'center', padding: '10px' }}>
                                                        Sem informações no momento
                                                    </div>
                                                ),
                                            }}
                                        />
                                    </div>
                                </Paper>
                            )}
                        </div>

                    </Grid>


                    <Grid item xs={12} md={12} lg={12} style={{ marginTop: 40 }}>

                        <h5>Ranking de Atendimentos Atendidos
                            <Tooltip title={`Este gráfico exibe o número de atendimentos respondidos por cada usuário dentro do período
                             de tempo especificado. Ele mostra quantas atendimentos que cada usuário respondeu,
                             permitindo uma rápida visualização da distribuição do trabalho entre os membros da equipe`}>
                                <IconButton>
                                    <FaInfoCircle size={15} />
                                </IconButton>
                            </Tooltip>
                            <Button variant="outlined" style={{ gap: 10, marginLeft: 10 }} onClick={() => handleAi('rankingTickets')}>
                                <PiBrain /> Entender Gráfico
                            </Button>
                        </h5>

                        {dataTicketsAnswered && dataTicketsAnswered.length >= 1 ?
                            <div style={{ height: 500, width: '100%' }}>
                                <BarChart2
                                    data={dataTicketsAnswered}
                                    title={'Atendimentos Atendidos'}
                                    type={'answeredTickets'}
                                    theme={selectedTheme}
                                />
                            </div> : dataTicketsAnswered && dataTicketsAnswered.length === 0 ?
                                <div className="boxChart"
                                    style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    Nada encontrado
                                </div> :
                                <div className="boxChart"
                                    style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    Carregando dados...
                                </div>
                        }

                    </Grid>


                    <Grid item xs={12} md={10} lg={12}>


                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 15 }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h5>Ranking de Clientes que mais trocaram mensagens</h5>
                                <Tooltip title={`Este gráfico exibe o número de mensagens respondidas
                                por cada cliente/contato dentro do período de tempo especificado. Ele mostra
                                quantas mensagens cada cliente/contato respondeu.`}>
                                    <IconButton>
                                        <FaInfoCircle size={15} />
                                    </IconButton>
                                </Tooltip>
                                <Button variant="outlined" style={{ gap: 10, marginLeft: 10 }} onClick={() => handleAi('rankingContacts')}>
                                    <PiBrain /> Entender Gráfico
                                </Button>
                            </div>


                            <div style={{ display: 'flex' }}>
                                <IconButton onClick={() => handleModeChart('msgsClients', 'chart')}>
                                    <FaChartBar />
                                </IconButton>
                                <IconButton onClick={() => handleModeChart('msgsClients', 'table')}>
                                    <FaTable />
                                </IconButton>
                            </div>
                        </div>
                        {chartClientsOption === 'chart' ? (
                            rowsClientsRanking && rowsClientsRanking.length >= 1 ?
                                <div style={{ height: 500, width: '100%' }}>
                                    <QtyChart
                                        data={rowsClientsRanking}
                                        title={'Mensagens Trocadas por Atendente'}
                                        type={'userMessages'}
                                        theme={selectedTheme}
                                    />
                                </div> : rowsClientsRanking && rowsClientsRanking.length === 0 ?
                                    <div className="boxChart"
                                        style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        Nada encontrado
                                    </div> :
                                    <div className="boxChart"
                                        style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        Carregando dados...
                                    </div>

                        ) : (<Paper
                            className={classes.mainPaperTable}
                            variant="outlined"
                        //  onScroll={handleScroll}
                        >
                            <div style={{ height: 400, width: '100%' }}>
                                <DataGrid
                                    rows={rowsClientsRanking}
                                    columns={columnsClientsRanking}
                                    sortModel={sortClientsRankingModel}
                                    onSortModelChange={(newSortModel) => setSortClientsRankingModel(newSortModel)}
                                    localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                                    pageSize={5}
                                    checkboxSelection
                                    getRowId={getRowClientsRankingId}
                                    components={{
                                        NoRowsOverlay: () => (
                                            <div style={{ textAlign: 'center', padding: '10px' }}>
                                                Sem informações no momento
                                            </div>
                                        ),
                                    }}
                                />
                            </div>
                        </Paper>)}
                    </Grid>
                </Grid>
            )
            }


        </Box >
    )
}

export default Metrics;